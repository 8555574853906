<template>
  <!-- //页面交互部分 -->
  <div class="homeInfo">
    <div class="mediaContainer">
      <video
        class="videoBox"
        id="videoBox"
        preload="auto"
        :controls="false"
        src="../assets/video/us.mp4"
      ></video>
      <div class="playBtn" v-if="playFlag" @click="playVideo">
        <span class="el-icon-video-play"></span>
      </div>
      <div @click="intoModelPage" class="backIndex backIndex1">
        <img src="../assets/images/home.svg" alt="" />
        <div class="backHomeBtn">返回主页</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DisVideo",
  data() {
    return {
      playFlag: true,
    };
  },
  methods: {
    //页面交互部分
    intoModelPage() {
      this.$router.push({ name: "Building" });
    },
    playVideo() {
      this.playFlag = false;
      const elVideo = document.getElementById("videoBox");
      elVideo.play();
      let that = this;
      elVideo.addEventListener(
        "ended",
        function () {
          //结束
          //视频隐藏
          this.playFlag = true;
          that.$router.push({ name: "Building" });
        },
        false
      );
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.playFlag = true;
    });
  },
  destroyed() {
    // const elVideo = document.getElementById('videoBox');
    // elVideo.currentTime = 0.1
  },
  mounted() {
    console.log(this.playFlag);
  },
};
</script>
<style lang="less" scoped>
//页面交互
.homeInfo {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 20000;
}
.mediaContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  z-index: 19888;
  display: block;
}
.playBtn {
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 35px);
  font-size: 70px;
  width: 70px;
  height: 70px;
  color: #fff;
  cursor: pointer;
  span {
    display: inline-block;
    vertical-align: top;
    height: 70px;
    margin-top: 2px;
    line-height: 32px;
  }
}
.backIndex {
  position: absolute;
  top: 100px;
  right: 100px;
  height: 36px;
  width: 120px;
  /* vertical-align: middle; */
  border-radius: 4px;
  padding-top: 4px;
  box-sizing: border-box;
  padding-left: 10px;
  cursor: pointer;
  background-image: url("../assets/images/infoBg.png");
  color: #fff;
  img {
    width: 24px;
    vertical-align: middle;
  }
}
.videoBox {
  width: 100%;
  height: 100%;
  background: #000;
  // object-fit: fill;
}
/*video默认全屏按钮*/
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
:-webkit-full-screen video {
  width: 100%;

  height: 100%;
}

:-moz-full-screen video {
  width: 100%;

  height: 100%;
}
.hiddenBox {
  display: none !important;
}
.loadingBtn {
  position: absolute;
  bottom: 100px;
  right: 140px;
}
.backHomeBtn {
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.mediaContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19888;
  display: block;
}
.videoBox {
  width: 100%;
  height: 100%;
  background: #000;
  object-fit: fill;
	transform: translate3d(0, 0, 0);
}
/*video默认全屏按钮*/
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
:-webkit-full-screen video {
  width: 100%;

  height: 100%;
}

:-moz-full-screen video {
  width: 100%;

  height: 100%;
}
</style>